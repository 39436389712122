import getCampaignData from '../../../lib/getCampaignData'
import { defaults } from './_defaults'

export default function campaign() {
    const start = '9/16/2024'
    const end = '10/28/2024'
    const amount = 0

    const data = {
        // defaults
        bu: 'wbu',
        defaultAmount: 0,
        defaultCampaignId: defaults.leadCampaignId,
        defaultSubscriberId: '7013q000001kmYIAAY',
        defaultHero: 'rebate',
        defaultCtaText: '',
        defaultImage: '/images/lifestyle/interior/wbu-fall-hero.webp',
        defaultTitle: defaults.title,
        defaultBlurb: defaults.blurb,
        defaultDetails:
            "Take control of your home's comfort by choosing a ductless heat pump system. Perfect for your home office, basement, garage or any unconditioned space in your home.",
        defaultFootnote: '',
        utilityProduct: defaults.utilityProduct,
        whatsNext: defaults.whatsNext,
        submitFormBtnText: null,
        // ME_Residential_WBU_Fall_2024_NorCal_CFM
        start: start,
        end: end,
        campaignAmount: amount,
        campaignId: '701Pd0000070jvdIAA',
        campaignHero: 'rebate',
        campaignCtaText: '',
        campaignImage: '/images/lifestyle/interior/wbu-fall-hero.webp',
        campaignTitle: defaults.title,
        campaignBlurb: defaults.blurb,
        campaignDetails:
            "Take control of your home's comfort by choosing a ductless heat pump system. Perfect for your home office, basement, garage or any unconditioned space in your home.",
        campaignFootnote: ``,
        hideContractorNumSelector: true,
        hideTimeframeSelector: false,
        hideContactMethodSelector: false,
        hideLocationSelectors: false,
        hideSystemSelector: false,
        hideCommunicationsSelector: false,
        hideDownArrow: false,
    }

    const finalData = getCampaignData(data)

    return finalData
}
